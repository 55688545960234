import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/es5/util/colors';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

const opts = {
	theme: {
		light: true,
		themes: {
			light: {
				primary: '#7BB03F',
				secondary: '#e56208',
				accent: '#7BB03F',
				error: colors.red.darken1
			}
		}
	},
	icons: {
		iconfont: 'fa'
	},
	lang: {
		locales: { es },
		current: 'es'
	}
};

export default new Vuetify(opts);
