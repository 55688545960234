export default {
	global: {
		appVersion: '1.0.0-SNAPSHOT',
		appDefaultEntryPoint: 'home',
		baseUrl: '/agrogestor',
		appLoginDisplayArea: true
	},
	menu: {
		showLeftBar: true,
		keepExpanded: true,
		searchBtn: false,
        notificationsBtn: false,
        helpBtn: false
	},
	login: {
		forgetPassword: true
	},
	grid: {
		showRowActions: true
	},
	form: {
		showSaveAndUpdate: true,
		showSaveAndNew: true
	},
	puidocument: {
		enableDragDrop: true
	}
};
