import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// GEN_REGISTER_COMPONENT_START
Vue.component('agc-grid', () => import('@/components/agc/AgcGrid'));
Vue.component('agc-form', () => import('@/components/agc/AgcForm'));
Vue.component('itinerario-grid', () => import('@/components/itinerario/ItinerarioGrid'));
Vue.component('itinerario-form', () => import('@/components/itinerario/ItinerarioForm'));
Vue.component('paprogramaambiental-grid', () => import('@/components/paprogramaambiental/PaProgramaAmbientalGrid'));
Vue.component('paprogramaambiental-form', () => import('@/components/paprogramaambiental/PaProgramaAmbientalForm'));
// GEN_REGISTER_COMPONENT_END

Vue.component('HomePanel', () => import('@/components/home/HomePanel'));
Vue.component('applogindisplayarea', () => import('@/components/login/AppLoginDisplayArea'));
Vue.component('External', () => import('@/components/external/External'));

const puiRoutes = [
	{
		path: '/',
		redirect: 'home'
	},
	{
		path: 'home',
		component: () => import('@/components/home/HomePanel')
	},
	{
		path: 'session',
		component: () => import('pui9-admin/src/components/config/puisession/PuiSessionGrid.vue')
	},
	{
		path: 'puilanguage',
		component: () => import('pui9-admin/src/components/config/puilanguage/PuiLanguageGrid.vue')
	},
	{
		path: '(.*/)?puilanguage/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/config/puilanguage/PuiLanguageForm.vue')
	},
	{
		path: 'puivariable',
		component: () => import('pui9-admin/src/components/config/puivariable/PuiVariableGrid.vue')
	},
	{
		path: '(.*/)?puivariable/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/config/puivariable/PuiVariableForm.vue')
	},
	{
		path: 'puivariable',
		component: () => import('pui9-admin/src/components/config/puivariable/PuiVariableGrid.vue')
	},
	{
		path: '(.*/)?puivariable/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/config/puivariable/PuiVariableForm.vue')
	},
	{
		path: 'puidocument',
		component: () => import('pui9-documents/src/components/PuiDocumentGrid.vue')
	},
	{
		path: '(.*/)?puidocument/:method/:pk',
		props: true,
		component: () => import('pui9-documents/src/components/PuiDocumentForm.vue')
	},
	{
		path: 'puidocgentemplate',
		component: () => import('pui9-docgen/src/components/puidocgen/PuiDocgenTemplateGrid.vue')
	},
	{
		path: '(.*/)?puidocgentemplate/:method/:pk',
		props: true,
		component: () => import('pui9-docgen/src/components/puidocgen/PuiDocgenTemplateForm')
	},
	{
		path: 'puiaudit',
		component: () => import('pui9-admin/src/components/config/puiaudit/PuiAuditGrid.vue')
	},
	{
		path: 'puiuser',
		component: () => import('pui9-admin/src/components/admin/puiuser/PuiUserGrid.vue')
	},
	{
		path: '(.*/)?puiuser/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/admin/puiuser/PuiUserForm.vue')
	},
	{
		path: 'usersettings',
		component: () => import('pui9-admin/src/components/admin/puiuser/PuiUserSettingsForm.vue')
	},
	{
		path: 'puiprofile',
		component: () => import('pui9-admin/src/components/admin/puiprofile/PuiProfileGrid.vue')
	},
	{
		path: '(.*/)?puiprofile/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/admin/puiprofile/PuiProfileForm.vue')
	},
	{
		path: 'puifunctionality',
		component: () => import('pui9-admin/src/components/admin/puifunctionality/PuiFunctionalityGrid.vue')
	}
];

const appRoutes = [
	// GEN_ROUTES_START
	{
		path: 'agc',
		component: () => import('@/components/agc/AgcGrid.vue')
	},
	{
		path: '(.*)agc/:method/:pk',
		props: true,
		component: () => import('@/components/agc/AgcForm.vue')
	},
	{
		path: 'itinerariotecnico',
		component: () => import('@/components/itinerario/ItinerarioGrid.vue')
	},
	{
		path: '(.*)itinerariotecnico/:method/:pk',
		props: true,
		component: () => import('@/components/itinerario/ItinerarioForm.vue')
	},
	{
		path: 'paprogramaambiental',
		component: () => import('@/components/paprogramaambiental/PaProgramaAmbientalGrid.vue')
	},
	{
		path: '(.*)paprogramaambiental/:method/:pk',
		props: true,
		component: () => import('@/components/paprogramaambiental/PaProgramaAmbientalForm.vue')
	},
	// GEN_ROUTES_END
	{
		path: 'external',
		component: () => import('@/components/external/External.vue')
	},
	{
		path: 'externalproy',
		component: () => import('@/components/external/ExternalProy.vue')
	}

];

function setAuthRequired(route) {
	return { ...route, meta: { ...route.meta, requiresAuth: true } };
}

const mergedRouter = {
	mode: 'history',
	hash: false,
	base: '/',
	routes: [
		{
			path: '/login',
			component: () => import('pui9-base/src/components/PuiIntroLayout')
		},
		{
			path: '/',
			component: () => import('pui9-base/src/components/PuiBaseLayout'),
			children: puiRoutes.concat(appRoutes).map(setAuthRequired)
		}
	]
};

export default new VueRouter(mergedRouter);
